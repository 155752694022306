.wrapper-navbar {
  display: flex;
  justify-content: center;
}

.list {
  display: flex;
  flex-direction: row;
  gap: 20px;
  list-style-type: none;
}
