.title-login {
  text-align: center;
}

.input-wrapper {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
